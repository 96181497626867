require('../../images/addToCartLoader.gif');
require('../../images/bckg.jpg');
require('../../images/headerBckg.jpg');
require('../../images/icon-exit.gif');
require('../../images/indexHeaderBckg.jpg');
require('../../images/logoIndex.png');
require('../../images/logo.png');
require('../../images/mask.png');
require('../../images/priceLoader.gif');

// Select all matching <a> elements
const links = document.querySelectorAll('.categories .level-0 > li > a');

// Loop through the elements using forEach
links.forEach((link) => {
  const href = link.getAttribute('href');
  if (href) {
    // Create a RegExp using the href attribute
    const urlPattern = new RegExp(href);

    // Build the current path (with a leading slash) and check for a match
    if (`/${window.location.pathname.substring(1)}`.match(urlPattern)) {
      // Add 'active' class to the parent <li>
      link.parentElement?.classList.add('active');

      // Add 'noBorder' class to the next sibling if it exists
      link.parentElement?.nextElementSibling?.classList.add('noBorder');
    }
  }
});

document.querySelectorAll('a').forEach((a) => {
  const attrHref = a.getAttribute('href');

  if (attrHref) {
    // Remove a trailing slash if present
    const href = /^(.+)\/$/.test(attrHref)
      ? attrHref.replace(/^(.+)\//, '$1')
      : attrHref;

    // If it matches the current path
    if (href === window.location.pathname) {
      // Create a replacement span
      const span = document.createElement('span');
      // Copy the HTML content from the anchor
      span.innerHTML = a.innerHTML;
      // Add a custom class
      span.classList.add('linkReplacement');

      // Insert the <span> after the <a>
      a.insertAdjacentElement('afterend', span);
      // Remove the original <a>
      a.remove();
    }
  }
});

// Select all <li> elements in the .header .categories ul
document.querySelectorAll('.header .categories ul li').forEach((li) => {
  // MOUSE ENTER
  li.addEventListener('mouseenter', (event) => {
    const hoveredLi = event.currentTarget;
    if (hoveredLi instanceof HTMLElement) {
      const childUl = hoveredLi.querySelector(':scope > ul');
      if (childUl instanceof HTMLElement) {
        childUl.style.display = 'block';
      }

      hoveredLi.classList.add('hover');

      // Add class to next sibling
      if (hoveredLi.nextElementSibling) {
        hoveredLi.nextElementSibling.classList.add('noHoverBorder');
      }

      // If the previous sibling has class 'active'
      if (hoveredLi.previousElementSibling && hoveredLi.previousElementSibling.classList.contains('active')) {
        hoveredLi.classList.add('noPaddingLeft');
      }

      // If the next sibling has class 'active'
      if (hoveredLi.nextElementSibling && hoveredLi.nextElementSibling.classList.contains('active')) {
        hoveredLi.nextElementSibling.classList.add('noPaddingLeft');
      }

      // Hide any <ul> whose ancestors don’t have .hover
      const parentUl = hoveredLi.closest('ul');
      parentUl?.querySelectorAll('li > ul').forEach((ul) => {
        if (ul instanceof HTMLElement && !ul.closest('.hover')) {
          // eslint-disable-next-line no-param-reassign
          ul.style.display = 'none';
        }
      });
    }
  });

  // MOUSE LEAVE
  li.addEventListener('mouseleave', (event) => {
    const hoveredLi = event.currentTarget;
    if (hoveredLi instanceof HTMLElement) {
      const childUl = hoveredLi.querySelector(':scope > ul');

      hoveredLi.classList.remove('hover', 'noPaddingLeft');

      if (hoveredLi.nextElementSibling) {
        hoveredLi.nextElementSibling.classList.remove('noHoverBorder', 'noPaddingLeft');
      }

      setTimeout(() => {
        if (childUl instanceof HTMLElement) {
          childUl.style.display = 'none';
        }
      }, 100);
    }
  });
});
